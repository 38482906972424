import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import sliderImage1 from '../../assets/images/pick_story_type_img.png';
import sliderImage2 from '../../assets/images/slider_img_2.png';
import sliderImage3 from '../../assets/images/slider_img_3.png';
import sliderImage4 from '../../assets/images/slider_img_4.png';
import sliderImage5 from '../../assets/images/slider_img_5.png';
import sliderImage6 from '../../assets/images/slider_img_6.png';
import sliderImage7 from '../../assets/images/slider_img_7.png';
import sliderImage8 from '../../assets/images/slider_img_8.png';
import sliderImage9 from '../../assets/images/slider_img_9.png';
import SliderNextButton from '../shared/SliderNextButton';
import SliderPrevButton from '../shared/SliderPrevButton';

const interfaceslider = {
  loop: true,
  effect: 'coverflow',
  margin: 20,
  centeredSlides: false,
  coverflowEffect: {
    rotate: 0,
    stretch: 0,
    depth: 100,
    modifier: 4,
    slideShadows: false,
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
    1000: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
};

const featureItems = [
  {
    image: sliderImage1,
  },
  {
    image: sliderImage2,
  },
  {
    image: sliderImage3,
  },
  {
    image: sliderImage4,
  },
  {
    image: sliderImage5,
  },
  {
    image: sliderImage6,
  },
  {
    image: sliderImage7,
  },
  {
    image: sliderImage8,
  },
  {
    image: sliderImage9,
  },
];

function Interface() {
  const swiperRef = useRef(null);

  const swipeNext = () => {
    swiperRef.current?.slideNext();
  };
  const swipePrev = () => {
    swiperRef.current?.slidePrev();
  };
  return (
    <>
      <section className="row_am interface_section">
        <div className="container-fluid">
          <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={300} data-aos-once>
            <span className="title_badge">App screens</span>
            <h2>
              Userfriendly <span>interface</span> design
            </h2>
          </div>
          <div className="screen_slider" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
            <Swiper
              id="screen_slider"
              ref={swiperRef}
              className="mySwiper"
              {...interfaceslider}
              spaceBetween={20}
              allowTouchMove={true}
              pagination={true}
              modules={[Pagination]}
              slidesPerView={5}
              onSwiper={(it) => (swiperRef.current = it)}
            >
              {featureItems.map((item, index) => {
                return (
                  <SwiperSlide className="item" key={index}>
                    <div className="screen_frame_img">
                      <img src={item.image} alt="image" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <SliderNextButton onClick={swipeNext} />
            <SliderPrevButton onClick={swipePrev} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Interface;
