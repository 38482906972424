const accordionData = {
  generalFirst: [
    {
      question: 'What is FairyTaler?',
      answer:
        'FairyTaler is a versatile storytelling app that allows users to read a variety of public stories and create their own unique tales. With support for iOS and Android, FairyTaler offers a rich, interactive experience with text, image, and video stories, complete with audio recordings and customizable elements.',
    },
    {
      question: 'How does FairyTaler enhance storytelling?',
      answer:
        'With FairyTaler, you can personalize stories using templates, select image styles, voice types, and even make your child the hero of the story. It’s storytelling reimagined for the digital age.',
    },
    {
      question: 'How do I sign up for FairyTaler?',
      answer:
        'Signing up is easy! You can use your Google, Apple, or Facebook account to create a FairyTaler account and start exploring the world of stories right away.',
    },
    {
      question: 'Can I create my own stories in FairyTaler?',
      answer:
        'Absolutely! FairyTaler empowers you to craft your own stories using a detailed story configuration template. You can choose the name, moral lesson, image style, voice type, and more to make your story truly yours.',
    },
  ],
  generalSecond: [
    {
      question: 'What types of stories can I find and create?',
      answer:
        'There are three types of stories available: Text stories, Image stories, and Video stories. Each story type offers a unique way to experience the narrative, with image and video stories featuring audio recordings for an immersive storytelling experience.',
    },
    {
      question: 'How does the story creation process work?',
      answer:
        'Creating a story is a seamless process. Once you’ve selected your story type and input your content, images and videos are generated in parallel, with an average generation time of 8 seconds for the first page and 5 seconds for subsequent pages.',
    },
    {
      question: 'Is there a way to share stories with others?',
      answer:
        'Yes, you can share your stories with friends via a deeplink that includes the story ID and a readKey. Public stories’ deeplinks only include the story ID.',
    },
    {
      question: 'How do I create a Baby Profile?',
      answer:
        'To create a Baby Profile, navigate to the ‘My Profile’ section in the settings and select ‘Baby Profile’. Here, you can add and edit your baby’s profile data. Once saved, this profile can be selected when creating a new story.',
    },
  ],

  pricing: [
    {
      question: 'What subscription plans does FairyTaler offer?',
      answer:
        'FairyTaler provides three subscription tiers: Basic, Advanced, and Premium. Each tier unlocks different features, from creating text stories to enjoying all story types.',
    },
    {
      question: 'How does the free trial work?',
      answer:
        'New users can enjoy a 3-day free trial, followed by discounted rates before the standard pricing kicks in. It’s a great way to explore the app’s full potential.',
    },
    {
      question: 'What payment methods are accepted for subscriptions?',
      answer:
        'FairyTaler accepts a variety of payment methods, including credit/debit cards and mobile payment platforms, to make the subscription process convenient for you.',
    },
    {
      question: 'Can I cancel my subscription at any time?',
      answer:
        'Yes, you have the flexibility to cancel your subscription whenever you wish. The cancellation will be effective at the end of the current billing cycle.',
    },
  ],

  account: [
    {
      question: 'How do I manage my profile and preferences?',
      answer:
        'In ‘My Profile,’ you can set app and story languages, manage usage limits, and customize your baby’s profile to personalize stories.',
    },
    {
      question: 'Can I change my subscription plan?',
      answer: 'Yes, you can upgrade or downgrade your subscription at any time through the Premium screen in the app.',
    }
  ],

  support: [
    {
      question: 'How do I report a technical issue?',
      answer:
        'If you encounter any problems, please use the ‘Contact us’ feature in the app, which will direct you to our support team via email.',
    },
    {
      question: 'Will my stories be saved if I delete my account?',
      answer:
        'Please note that deleting your account will remove all associated data, and stories cannot be recovered afterward.',
    },
    {
      question: 'What should I do if the app is not working properly?',
      answer:
        'If you encounter any issues with the app, please try restarting it or check for updates. If the problem persists, reach out to our technical support team for help.',
    },
    {
      question: 'How can I suggest new features or improvements for FairyTaler?',
      answer:
        'We love hearing from our users! You can send us your suggestions through the ‘Contact us’ option in the app or via our social media channels.',
    },
  ],

  privacy: [
    {
      question: 'How does FairyTaler ensure the safety of my data?',
      answer:
        'FairyTaler takes your privacy seriously. We employ robust security measures to protect your personal information and story content. Our commitment to data safety is unwavering.',
    },
    {
      question: 'What personal data does FairyTaler collect?',
      answer:
        'We collect only the essential data needed to enhance your storytelling experience, such as your chosen app language, story preferences, and baby profile information, which is fully controlled by you.',
    },
    {
      question: 'How does FairyTaler handle data breaches?',
      answer:
        'In the unlikely event of a data breach, we have protocols in place to promptly address the issue and communicate with affected users about the steps being taken.',
    },
    {
      question: 'What measures are in place to protect children’s privacy in FairyTaler?',
      answer:
        'FairyTaler complies with all relevant privacy laws and regulations to ensure that children’s data is handled with the utmost care and security.',
    },
  ],
};

export default accordionData;
