import React from 'react';
import { Link } from 'react-router-dom';
import Google from '../../assets/images/google.png';
import AppleIcon from '../../assets/images/apple.png';
import smileImage from '../../assets/images/smile.svg';
import eugenePhoto from '../../assets/images/eugene_img.jpg';
import juliaPhoto from '../../assets/images/julia_photo.jpg';
import ashleyPhoto from '../../assets/images/ashley_photo.jpg';

const reviewData = [
  {
    name: 'Eugene',
    stars: 5,
    text: "My daughter eagerly looks forward to a new story every night. We love creating daily stories based on what she's seen throughout the day. Definitely a thumbs-up for this app!",
    avatar: eugenePhoto,
  },
  {
    name: 'Ashley',
    stars: 5,
    text: 'Brilliant! I use GPT a lot and love seeing it combined with images in this app',
    avatar: ashleyPhoto,
  },
  {
    name: 'Julia',
    stars: 5,
    text: 'The idea behind the app is very cool! The child really enjoyed making up and then listening to the new story',
    avatar: juliaPhoto,
  },
];

function Review() {
  return (
    <>
      <section className="review_section row_am">
        <div className="anchor" id="reviews" />
        <div className="container">
          <div className="positive_inner">
            <div className="row">
              <div className="col-md-6 sticky-top">
                <div className="sidebar_text" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
                  <div className="section_title text-left">
                    <span className="title_badge">Reviews</span>
                    <h2>
                      <span>Positive reviews </span> <br /> of our clients
                    </h2>
                  </div>
                  <div className="google_rating">
                    <div className="star">
                      {Array.from({ length: 5 }, (_, i) => (
                        <span key={i}>
                          <i className="icofont-star" />
                        </span>
                      ))}
                    </div>
                    <p>
                      5.0 Rated on <img className="img-fluid" src={Google} alt="image" />
                    </p>
                  </div>

                  {/* <div className="google_rating">
                    <div className="star">
                      {Array.from({ length: 5 }, (_, i) => (
                        <span key={i}>
                          <i className="icofont-star" />
                        </span>
                      ))}
                    </div>
                    <p>
                      5.0 Rated on <img className="img-fluid" src={Google} alt="image" />
                    </p>
                  </div> */}
                  {/* <div className="user_review" >
                    <p>
                      1399{' '}
                      <Link to="#">
                        Total user reviews <i className="icofont-arrow-right" />{' '}
                      </Link>
                    </p>
                  </div> */}
                  <div className="smiley_icon">
                    <img src={smileImage} alt="image" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="review_side">
                  {reviewData.map((review, index) => (
                    <div key={index} className="review_block" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
                      <div className="coustomer_info">
                        <div className="avtar">
                          <img src={review.avatar} alt="image" />
                          <div className="text">
                            <h3>{review.name}</h3>
                          </div>
                        </div>
                        <div className="star">
                          {Array.from({ length: review.stars }, (_, i) => (
                            <span key={i}>
                              <i className="icofont-star" />
                            </span>
                          ))}
                        </div>
                      </div>
                      <p>{review.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Review;
