import { BrowserRouter as Router } from 'react-router-dom';
import Routing from './routes';
import './style/aos.css';
import './style/bootstrap.min.css';
import './style/icofont.min.css';
import './style/magnific-popup.min.css';
import './style/owl.carousel.min.css';
import './style/style.css';
import './style/responsive.css';

export default function App() {
  return (
    <>
      <Router>
        <Routing />
      </Router>
    </>
  );
}
