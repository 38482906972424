import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../Components/Home/Main';

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
};

export default Routing;
