import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function Main() {
  const [headerScroll, setheaderScroll] = useState(false);
  const [mobile, setmobile] = useState();

  useEffect(() => {
    const fixedHeader = () => {
      if (window.scrollY > 150) {
        setheaderScroll(true);
      } else {
        setheaderScroll(false);
      }
    };
    window.addEventListener('scroll', fixedHeader);
  }, []);

  const handleScrollToBlock = (elementId) => {
    if (elementId === 'home') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }

    const element = document.getElementById(elementId);

    element?.scrollIntoView({
      behavior: 'smooth',
      top: 60,
    });
  };

  return (
    <>
      <header className={headerScroll ? 'fixed fix_style' : 'fixed'}>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="Logo" />
            </Link>
            <button
              className={`navbar-toggler ${mobile && 'collapsed'}`}
              onClick={() => setmobile(mobile === true ? false : true)}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div
                className={`toggle-wrap ${mobile && 'active'}`}
                onClick={() => setmobile(mobile === true ? false : true)}
              >
                <span className="toggle-bar"></span>
              </div>
            </button>
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <button onClick={() => handleScrollToBlock('home')}>Home</button>
                </li>
                <li className="nav-item">
                  <button onClick={() => handleScrollToBlock('key_features')}>Features</button>
                </li>
                <li className="nav-item">
                  <button onClick={() => handleScrollToBlock('about_us')}>Results</button>
                </li>
                <li className="nav-item">
                  <button onClick={() => handleScrollToBlock('services')}>Story Types</button>
                </li>
                <li className="nav-item">
                  <button onClick={() => handleScrollToBlock('reviews')}>Reviews</button>
                </li>
                <li className="nav-item">
                  <button onClick={() => handleScrollToBlock('faq')}>FAQ</button>
                </li>
                <li className="nav-item">
                  <div className="btn_block">
                    <button className="nav-link dark_btn" onClick={() => handleScrollToBlock('download')}>
                      Download
                    </button>
                    <div className="btn_bottom" />
                  </div>
                </li>
              </ul>
            </div>

            {mobile && (
              <>
                <div className={`navbar-collapse collapse custom_nav ${mobile && 'show'}`} id="navbarSupportedContent">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                      <button onClick={() => handleScrollToBlock('home')}>Home</button>
                    </li>
                    <li className="nav-item">
                      <button onClick={() => handleScrollToBlock('key_features')}>Features</button>
                    </li>
                    <li className="nav-item">
                      <button onClick={() => handleScrollToBlock('about_us')}>Results</button>
                    </li>
                    <li className="nav-item">
                      <button onClick={() => handleScrollToBlock('services')}>Story Types</button>
                    </li>
                    <li className="nav-item">
                      <button onClick={() => handleScrollToBlock('reviews')}>Reviews</button>
                    </li>
                    <li className="nav-item nav_centered">
                      <div className="btn_block">
                        <button className="nav-link dark_btn" onClick={() => handleScrollToBlock('download')}>
                          Download
                        </button>
                        <div className="btn_bottom" />
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </nav>
        </div>
      </header>
    </>
  );
}

export default Main;
