import React from 'react';
import googleplay from '../../assets/images/google-play.svg';
import appstore from '../../assets/images/app-store.svg';

const DownloadBlock = () => {
  return (
    <div className="btn_block">
      <ul className="app_btn button_group">
        <li>
          <a
            className="button_group_item"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.softupper.fairytailer"
          >
            <img className="download_img" src={googleplay} alt="image" />
          </a>
        </li>
        <li>
          <a
            className="button_group_item"
            target="_blank"
            href="https://apps.apple.com/by/app/fairytaler-bedtime-ai-stories/id6476602417"
          >
            <img className="download_img" src={appstore} alt="image" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default DownloadBlock;
