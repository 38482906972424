import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import DownloadBlock from '../shared/DownloadBlock';

function Main() {
  const handleScrollToBlock = (elementId) => {
    const element = document.getElementById(elementId);
    element?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <>
      <footer className="white_text" data-aos="fade-in" data-aos-duration={1500} data-aos-once>
        <div className="container">
          <div className="row">
            <div className="col-md-6 footer_col_lg_6">
              <div className="logo_side">
                <div className="logo">
                  <Link to="#">
                    <img src={logo} alt="Logo" />
                  </Link>
                </div>
                <div className="contact_info">
                  <a href="mailto:app-support@softupper.com">app-support@softupper.com</a>
                </div>
              </div>
              {/* <ul className="social_media">
                <li>
                  <a href="#">
                    <i className="icofont-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icofont-twitter" />
                  </a>
                </li>
              </ul> */}
            </div>
            <div className="col-md-6 footer_col_lg_6">
              <DownloadBlock />
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="ft_inner">
              <div className="copy_text">
                <p>© Copyrights 2024. All rights reserved.</p>
              </div>
              <ul className="links">
                <li>
                  <button onClick={() => handleScrollToBlock('home')}>Home</button>
                </li>
                <li>
                  <button onClick={() => handleScrollToBlock('key_features')}>Features</button>
                </li>
                <li>
                  <button onClick={() => handleScrollToBlock('about_us')}>Resuls</button>
                </li>
                <li>
                  <button onClick={() => handleScrollToBlock('services')}>Story Types</button>
                </li>
                <li>
                  <button onClick={() => handleScrollToBlock('reviews')}>Reviews</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Main;
