import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Banner1 from '../../assets/images/banavt1.png';
import Banner2 from '../../assets/images/banavt2.png';
import Banner3 from '../../assets/images/banavt3.png';
import Play from '../../assets/images/play.svg';
import smallStar from '../../assets/images/smallStar.png';
import bigstar from '../../assets/images/bigstar.png';
import DownloadBlock from '../shared/DownloadBlock';
import mainBannerImage from '../../assets/images/main_banner.png';

function Banner() {
  const [ytban, setytban] = useState(false);

  return (
    <>
      <section className="banner_section" id="home">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
              <div className="banner_text">
                <h1>
                  Unlock the <span>Magic of Bedtime</span> with FairyTaler!
                </h1>
                <p>
                  Discover the enchanting world of personalized storytelling with FairyTaler. Our app uses the
                  power of AI to create unique fairy tales designed specifically for your child. With awesome visuals,
                  voice acting, and animated scenes, every bedtime becomes a magical adventure.
                </p>
              </div>
              <div className="used_app">
                <ul>
                  <li>
                    <img src={Banner1} alt="image" />
                  </li>
                  <li>
                    <img src={Banner2} alt="image" />
                  </li>
                  <li>
                    <img src={Banner3} alt="image" />
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="popup-youtube play-button play_icon"
                      data-url="https://www.youtube.com/embed/LDj0UGIplBo?autoplay=1"
                      data-toggle="modal"
                      data-target="#myModal"
                      title="FairyTaler">
                      <img src={Play} alt="img" onClick={() => setytban(true)} />
                    </Link>
                  </li>
                </ul>
                <h3>5K+ Downloads</h3>
                <p>
                  Let your child star as the main hero <br /> in their own adventure
                </p>
              </div>
              <DownloadBlock />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="banner_slider">
                <div className="left_icon">
                  <img src={smallStar} alt="image" />
                </div>
                <div className="right_icon">
                  <img src={bigstar} alt="image" />
                </div>
                <div className="hero_img">
                  <img src={mainBannerImage} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {ytban && (
        <div
          className="modal fade youtube-video show"
          id="myModal"
          tabIndex={-1}
          style={{ display: 'block', paddingRight: 17 }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content" onClick={() => setytban(false)}>
              <button
                id="close-video"
                type="button"
                className="button btn btn-default text-right"
                data-dismiss="modal"
                onClick={() => setytban(false)}
              >
                <i className="icofont-close-line-circled" />
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe
                    id="youtubevideo"
                    width={640}
                    height={360}
                    allowFullScreen=""
                    src="https://www.youtube.com/embed/LDj0UGIplBo?autoplay=1"
                  />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Banner;
