import React from 'react';
import { Link } from 'react-router-dom';
import Bluedots from '../../assets/images/blue_dotes.png';
import DownloadBlock from '../shared/DownloadBlock';
import like from '../../assets/images/like.svg';
import profile from '../../assets/images/profile.svg';
import download from '../../assets/images/download.svg';

function HowWork() {
  return (
    <>
      <section className="how_it_section white_text">
        <div className="how_it_inner" data-aos="fade-in" data-aos-duration={1500} data-aos-once>
          <div className="dotes_blue">
            <img src={Bluedots} alt="image" />
          </div>
          <div className="container">
            <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
              <span className="title_badge">Quick &amp; easy</span>
              <h2>How it works in 3 steps</h2>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="steps_block step_border" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
                  <div className="steps">
                    <div className="icon">
                      <div className="steps_icon_wrapper">
                        <img src={download} alt="image" />
                      </div>
                    </div>
                    <div className="text">
                      <h3>Download app</h3>
                      <ul className="social">
                        <li>
                          <Link to="#">
                            <i className="icofont-brand-android-robot" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="icofont-brand-apple" />
                          </Link>
                        </li>
                      </ul>
                      <p>
                        Download App. It will work for <br /> Android &amp; IOS
                      </p>
                    </div>
                  </div>
                  <span className="step">01</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="steps_block step_border" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
                  <div className="steps">
                    <div className="icon">
                      <div className="steps_icon_wrapper">
                        <img src={profile} alt="image" />
                      </div>
                    </div>
                    <div className="text">
                      <h3>Create account</h3>
                      <span className="tag_text">Try it for free</span>
                      <p>
                        The app is FREE, Subscribe to unlock <br /> Custom AI generated stories
                      </p>
                    </div>
                  </div>
                  <span className="step">02</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="steps_block" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
                  <div className="steps">
                    <div className="icon">
                      <div className="steps_icon_wrapper">
                        <img src={like} alt="image" />
                      </div>
                    </div>
                    <div className="text">
                      <h3>Enjoy the app</h3>
                      <span className="tag_text">Read FAQs for any query</span>
                      <p>
                        Enjoy public stories for free and <br />
                        share amazing experience
                      </p>
                    </div>
                  </div>
                  <span className="step">03</span>
                </div>
              </div>
            </div>
            <div className="text-center">
              <DownloadBlock />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HowWork;
