import React, { useState } from 'react';
import accordionData from '../shared/faqData';

function Faq() {
  const [tabMenu, tabActive] = useState({ a: true });
  const [openAccordion, setOpenAccordion] = useState(null);
  const [first, setfirst] = useState(null);

  const toggleAccordion = (index) => {
    if (openAccordion === index) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(index);
    }
  };

  const togglefirst = (index) => {
    if (first === index) {
      setfirst(null);
    } else {
      setfirst(index);
    }
  };

  return (
    <>
      <section className="row_am faq_section">
        <div className="anchor" id="faq" />
        <div className="container">
          <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
            <span className="title_badge">Question &amp; Answer</span>
            <h2>
              <span>FAQs</span> - Frequently Asked Questions
            </h2>
          </div>
          <ul
            className="nav nav-tabs"
            id="myTab"
            role="tablist"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-once
          >
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${tabMenu.a && 'active'}`}
                onClick={() => tabActive({ a: true })}
                id="genral-tab"
                data-toggle="tab"
                data-target="#genral"
                type="button"
                role="tab"
                aria-controls="genral"
                aria-selected="true"
              >
                General
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${tabMenu.b && 'active'}`}
                onClick={() => tabActive({ b: true })}
                id="pricing-tab"
                data-toggle="tab"
                data-target="#pricing"
                type="button"
                role="tab"
                aria-controls="pricing"
                aria-selected="false"
              >
                Pricing and Subscriptions
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${tabMenu.c && 'active'}`}
                onClick={() => tabActive({ c: true })}
                id="account-tab"
                data-toggle="tab"
                data-target="#account"
                type="button"
                role="tab"
                aria-controls="account"
                aria-selected="false"
              >
                Account
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${tabMenu.d && 'active'}`}
                onClick={() => tabActive({ d: true })}
                id="returns-tab"
                data-toggle="tab"
                data-target="#returns"
                type="button"
                role="tab"
                aria-controls="returns"
                aria-selected="false"
              >
                Technical Support
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${tabMenu.e && 'active'}`}
                onClick={() => tabActive({ e: true })}
                id="support-tab"
                data-toggle="tab"
                data-target="#support"
                type="button"
                role="tab"
                aria-controls="support"
                aria-selected="false"
              >
                Safety and Privacy
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
            <div
              className={`tab-pane fade ${tabMenu.a && 'show active'}`}
              id="genral"
              role="tabpanel"
              aria-labelledby="genral-tab"
            >
              <div className="accordion" id="accordionExample">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="accordion" id="accordionExample">
                      {accordionData.generalFirst.map((item, index) => (
                        <div className="card" key={index}>
                          <div className="card-header" id={`heading${index}`}>
                            <h2 className="mb-0">
                              <button
                                className={`btn btn-link btn-block text-left ${openAccordion === index ? '' : 'collapsed'}`}
                                type="button"
                                onClick={() => toggleAccordion(index)}
                                aria-expanded={openAccordion === index ? 'true' : 'false'}
                                aria-controls={`collapse${index}`}
                              >
                                {' '}
                                {item.question}
                                <span className="icons">
                                  <i className={`icofont-${openAccordion === index ? 'minus' : 'plus'}`}></i>
                                </span>
                              </button>
                            </h2>
                          </div>
                          <div
                            id={`collapse${index}`}
                            className={`collapse ${openAccordion === index ? 'show' : ''}`}
                            aria-labelledby={`heading${index}`}
                            data-parent="#accordionExample"
                          >
                            <div className="card-body">{item.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="first" id="firstExample">
                      {accordionData.generalSecond.map((item, index) => (
                        <div className="card" key={index}>
                          <div className="card-header" id={`heading${index}`}>
                            <h2 className="mb-0">
                              <button
                                className={`btn btn-link btn-block text-left ${first === index ? '' : 'collapsed'}`}
                                type="button"
                                onClick={() => togglefirst(index)}
                                aria-expanded={first === index ? 'true' : 'false'}
                                aria-controls={`collapse${index}`}
                              >
                                {' '}
                                {item.question}
                                <span className="icons">
                                  <i className={`icofont-${first === index ? 'minus' : 'plus'}`}></i>
                                </span>
                              </button>
                            </h2>
                          </div>
                          <div
                            id={`collapse${index}`}
                            className={`collapse ${first === index ? 'show' : ''}`}
                            aria-labelledby={`heading${index}`}
                            data-parent="#firstExample"
                          >
                            <div className="card-body">{item.answer}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${tabMenu.b && 'show active'}`}
              id="pricing"
              role="tabpanel"
              aria-labelledby="pricing-tab"
            >
              {accordionData.pricing.map((item, index) => (
                <div className="card" key={index}>
                  <div className="card-header" id={`heading${index}`}>
                    <h2 className="mb-0">
                      <button
                        className={`btn btn-link btn-block text-left ${openAccordion === index ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        aria-expanded={openAccordion === index ? 'true' : 'false'}
                        aria-controls={`collapse${index}`}
                      >
                        {' '}
                        {item.question}
                        <span className="icons">
                          <i className={`icofont-${openAccordion === index ? 'minus' : 'plus'}`}></i>
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id={`collapse${index}`}
                    className={`collapse ${openAccordion === index ? 'show' : ''}`}
                    aria-labelledby={`heading${index}`}
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">{item.answer}</div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={`tab-pane fade ${tabMenu.c && 'show active'}`}
              id="account"
              role="tabpanel"
              aria-labelledby="account-tab"
            >
              {accordionData.account.map((item, index) => (
                <div className="card" key={index}>
                  <div className="card-header" id={`heading${index}`}>
                    <h2 className="mb-0">
                      <button
                        className={`btn btn-link btn-block text-left ${openAccordion === index ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        aria-expanded={openAccordion === index ? 'true' : 'false'}
                        aria-controls={`collapse${index}`}
                      >
                        {' '}
                        {item.question}
                        <span className="icons">
                          <i className={`icofont-${openAccordion === index ? 'minus' : 'plus'}`}></i>
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id={`collapse${index}`}
                    className={`collapse ${openAccordion === index ? 'show' : ''}`}
                    aria-labelledby={`heading${index}`}
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">{item.answer}</div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={`tab-pane fade ${tabMenu.d && 'show active'}`}
              id="returns"
              role="tabpanel"
              aria-labelledby="returns-tab"
            >
              {accordionData.support.map((item, index) => (
                <div className="card" key={index}>
                  <div className="card-header" id={`heading${index}`}>
                    <h2 className="mb-0">
                      <button
                        className={`btn btn-link btn-block text-left ${openAccordion === index ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        aria-expanded={openAccordion === index ? 'true' : 'false'}
                        aria-controls={`collapse${index}`}
                      >
                        {' '}
                        {item.question}
                        <span className="icons">
                          <i className={`icofont-${openAccordion === index ? 'minus' : 'plus'}`}></i>
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id={`collapse${index}`}
                    className={`collapse ${openAccordion === index ? 'show' : ''}`}
                    aria-labelledby={`heading${index}`}
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">{item.answer}</div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={`tab-pane fade ${tabMenu.e && 'show active'}`}
              id="support"
              role="tabpanel"
              aria-labelledby="support-tab"
            >
              {accordionData.privacy.map((item, index) => (
                <div className="card" key={index}>
                  <div className="card-header" id={`heading${index}`}>
                    <h2 className="mb-0">
                      <button
                        className={`btn btn-link btn-block text-left ${openAccordion === index ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        aria-expanded={openAccordion === index ? 'true' : 'false'}
                        aria-controls={`collapse${index}`}
                      >
                        {' '}
                        {item.question}
                        <span className="icons">
                          <i className={`icofont-${openAccordion === index ? 'minus' : 'plus'}`}></i>
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id={`collapse${index}`}
                    className={`collapse ${openAccordion === index ? 'show' : ''}`}
                    aria-labelledby={`heading${index}`}
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">{item.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
