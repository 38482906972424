import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

const Breakpoints = {
  320: {
    slidesPerView: 2.2,
    spaceBetween: 15,
  },
};

const textItems = [
  'AI-Powered Visuals',
  'Interactive Story Creation',
  'Multilingual Experience',
  'Custom Story Templates',
  'Social Story Sharing',
  'Dynamic Audio Narratives',
  'Secure Backup and Restoration',
  'Personalized User Profiles',
];

function Textlist() {
  return (
    <>
      <div className="text_list_section row_am" data-aos="fade-in" data-aos-duration={1500} data-aos-once>
        <div className="container">
          <span className="title_badge down_fix">Why choose our app</span>
        </div>
        <div className="slider_block">
          <Swiper className="swiper-wrapper" id="text_list_flow" slidesPerView={2.1} breakpoints={Breakpoints}>
            {textItems.map((item, index) => (
              <SwiperSlide key={index} className="item">
                <div className="text_block">
                  <span>{item}</span>
                  <span className="mark_star">•</span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Textlist;
