import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import yellow_dotes from '../../assets/images/yellow_dotes.png';
import downloadScreen from '../../assets/images/downloadScreen.png';
import DownloadBlock from '../shared/DownloadBlock';
import footerBanner from '../../assets/images/footer_banner.png';

const Textslider = {
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

function Text() {
  return (
    <>
      <div
        className="text_list_section row_am download_section"
        data-aos="fade-in"
        data-aos-duration={1500}
        data-aos-once
      >
        <div className="anchor" id="download" />
        <div className="container">
          <div className="yellow_dotes">
            <img src={yellow_dotes} alt="image" />
          </div>
          <div className="center_screen">
            <div className="img">
              <img src={footerBanner} alt="image" />
            </div>
          </div>
        </div>
        <div className="background_slider">
          <div className="dowanload_slider">
            <div className="downlist">
              <div className="text_block">
                <span>Download </span>
                <span className="mark_star">•</span>
              </div>
              <div className="text_block">
                <span>Download </span>
                <span className="mark_star">•</span>
              </div>
              <div className="text_block">
                <span>Download </span>
                <span className="mark_star">•</span>
              </div>
              <div className="text_block">
                <span>Download </span>
                <span className="mark_star">•</span>
              </div>
              <div className="text_block">
                <span>Download </span>
                <span className="mark_star">•</span>
              </div>
            </div>
          </div>
          <div className="slider_block">
            <Swiper className="Swiper-Text" id="text_list_flow_download" {...Textslider} slidesPerView={3}>
              <SwiperSlide className="item">
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
              </SwiperSlide>
              <SwiperSlide className="item">
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
              </SwiperSlide>
              <SwiperSlide className="item">
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
              </SwiperSlide>
              <SwiperSlide className="item">
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
              </SwiperSlide>
              <SwiperSlide className="item">
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
              </SwiperSlide>
              <SwiperSlide className="item">
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="dowanload_slider">
            <div className="downlist">
              <div className="text_block">
                <span>Download </span>
                <span className="mark_star">•</span>
              </div>
              <div className="text_block">
                <span>Download </span>
                <span className="mark_star">•</span>
              </div>
              <div className="text_block">
                <span>Download </span>
                <span className="mark_star">•</span>
              </div>
              <div className="text_block">
                <span>Download </span>
                <span className="mark_star">•</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Text;
