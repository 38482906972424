import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Application from '../../assets/images/applicationvideothumb.png';
import Playbtn from '../../assets/images/play_white.svg';
import Videobtn from '../../assets/images/playvideotext.png';
import DownloadBlock from '../shared/DownloadBlock';
import bannerImage from '../../assets/images/secondary_main_banner.png';

function Aboutus() {
  const [counts, setCounts] = useState({
    download: 5,
    reviews: 4,
    countries: 0,
    stories: 100,
  });

  useEffect(() => {
    const updateCounter = () => {
      const targetCounts = {
        download: 5,
        reviews: 4000,
        countries: 150,
        stories: 100,
      };

      const duration = 1500;
      const stepSize = 10;
      const steps = duration / stepSize;

      Object.keys(targetCounts).forEach((key) => {
        const targetCount = targetCounts[key];
        const currentCount = counts[key];
        const step = Math.ceil((targetCount - currentCount) / steps);

        if (step !== 0) {
          const interval = setInterval(() => {
            setCounts((prevCounts) => ({
              ...prevCounts,
              [key]: prevCounts[key] + step,
            }));
          }, stepSize);

          setTimeout(() => {
            clearInterval(interval);
            setCounts((prevCounts) => ({
              ...prevCounts,
              [key]: targetCount,
            }));
          }, duration);
        }
      });
    };

    updateCounter();
  }, []);

  const [ytshow, setytShow] = useState(false);

  return (
    <>
      <section className="about_section row_am">
        <div className="anchor" id="about_us" />
        <div className="container">
          <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
            <span className="title_badge mb-1">Resuls</span>
            <h2>
                Unlock the <span>Magic of Bedtime</span> with FairyTaler!
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration={1500} data-aos-once>
                <li data-aos="fade-up" data-aos-duration={1500} data-aos-once>
                  <div className="text">
                    <p>
                      <span className="counter-value">{counts.download}</span>
                      <span>K+</span>
                    </p>
                    <p>Download</p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500} data-aos-once>
                  <div className="text">
                    <p>
                      <span className="counter-value">{counts.stories}</span>
                      <span>+</span>
                    </p>
                    <p>Stories</p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500} data-aos-once>
                  <div className="text">
                    <p>
                      <span className="counter-value">{counts.countries}</span>
                      <span>+</span>
                    </p>
                    <p>Countries</p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500} data-aos-once>
                  <div className="text">
                    <p>
                      <span className="counter-value">{counts.reviews}</span>
                      <span>+</span>
                    </p>
                    <p>Happy Children</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="abtImg text-center" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
                <img src={bannerImage} alt="image" className="about_image" />
              </div>
            </div>
            <div className="col-lg-4">
              <p data-aos="fade-up" data-aos-duration={1500} data-aos-once>
                FairyTaler stands out as the premier AI-powered storytelling app, loved by users across the globe. With
                over 5000 downloads which made 4000+ childrens happy, 100+ public stories, and a presence in 150+ countries, we are committed to
                delivering an exceptional storytelling experience. Our advanced text and storyboard features ensure that
                every tale is not only unique but also engaging and educational.
              </p>
              <div className="video_block" data-aos="fade-up" data-aos-duration={1500} data-aos-once>
                <img className="thumbnil" src={Application} alt="image" />
                <div className="playBtn" onClick={() => setytShow(true)}>
                  <Link
                    to="#"
                    className="popup-youtube play-button play_icon"
                    data-url="https://www.youtube.com/embed/If2-kDjS8UI?autoplay=1"
                    data-toggle="modal"
                    data-target="#myModal"
                    title="XJj2PbenIsU"
                  >
                    <img src={Playbtn} alt="img" />
                  </Link>
                  <img className="spin_text" src={Videobtn} alt="image" />
                </div>
              </div>
              <DownloadBlock />
            </div>
          </div>
        </div>
      </section>

      {ytshow && (
        <div
          className="modal fade youtube-video show"
          id="myModal"
          tabIndex={-1}
          style={{ display: 'block', paddingRight: 17 }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content" onClick={() => setytShow(false)}>
              <button
                id="close-video"
                type="button"
                className="button btn btn-default text-right"
                data-dismiss="modal"
                onClick={() => setytShow(false)}
              >
                <i className="icofont-close-line-circled" />
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe
                    id="youtubevideo"
                    width={640}
                    height={360}
                    allowFullScreen=""
                    src="https://www.youtube.com/embed/If2-kDjS8UI?autoplay=1"
                  />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Aboutus;
