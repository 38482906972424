import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import blue_dotes from '../../assets/images/blue_dotes.png';
import SliderPrevButton from '../shared/SliderPrevButton';
import SliderNextButton from '../shared/SliderNextButton';
import featureImg1 from '../../assets/images/feature_1.png';
import featureImg2 from '../../assets/images/feature_2.png';
import featureImg3 from '../../assets/images/feature_3.png';
import featureImg4 from '../../assets/images/feature_4.png';
import featureImg5 from '../../assets/images/feature_5.png';
import featureImg6 from '../../assets/images/feature_6.png';
import featureImg7 from '../../assets/images/feature_7.png';
import featureImg8 from '../../assets/images/feature_8.png';
import featureImg9 from '../../assets/images/feature_9.png';
import palaceholder from '../../assets/images/feature1a.png';

const Breakpoints = {
  320: {
    slidesPerView: 1,
    spaceBetween: 30,
  },
  1000: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
};

const featureItems = [
  {
    title: 'Create Unique Stories',
    description:
      "Use FairyTaler's advanced AI to generate one-of-a-kind fairy tales. Tailor every aspect of the story to fit your child's interests, making each adventure special and memorable.",
    image: featureImg1,
  },
  {
    title: 'Your Child as the Hero',
    description:
      'Make your child the star of the story. With FairyTaler, your little one can embark on heroic journeys, face exciting challenges, and learn valuable moral lessons, all while being the main character.',
    image: featureImg2,
  },
  {
    title: 'Relive Daily Joys',
    description:
      "Turn everyday moments into magical stories. Generate tales inspired by your child's daily experiences, creating a wonderful way to relive and cherish those special memories.",
    image: featureImg7,
  },
  {
    title: 'Explore Different Story Types',
    description:
      'Discover stories in three captivating formats: Text stories, Image stories, and Video stories. Each type offers a unique way to experience the magic of bedtime.',
    image: featureImg8,
  },
  {
    title: 'Customizable Voice Acting',
    description:
      'Choose from a variety of voice options to narrate your stories. Whether you prefer a soothing voice or a more animated style, FairyTaler offers multiple voice types to make each story truly unique.',
    image: featureImg5,
  },
  {
    title: 'Customize Image Style',
    description:
      'Personalize your stories with customizable image styles. Choose from various AI models offering different artistic styles, ensuring each story is visually unique.',
    image: featureImg6,
  },
  {
    title: 'Collection of Public Stories',
    description:
      'Explore a diverse collection of pre-generated public stories across various genres. From classic tales to modern adventures, there’s something for everyone to enjoy.',
    image: featureImg3,
  },
  {
    title: 'Edit and Personalize',
    description:
      'Adjust any story to better tune for your child’s preferences and needs. Manually modify text to create a truly unique and personal storytelling experience.',
    image: featureImg9,
  },
  {
    title: 'Multi-Device Support',
    description:
      "Enjoy FairyTaler on both iOS and Android devices. Whether you're at home or on the go, your personalized fairy tales are always accessible, ensuring that storytime is never interrupted.",
    image: featureImg4,
  },
];

function Keyfeature() {
  const swiperRef = useRef(null);

  const swipeNext = () => {
    swiperRef.current?.slideNext();
  };
  const swipePrev = () => {
    swiperRef.current?.slidePrev();
  };
  return (
    <>
      <section className="key_feature_section row_am pt-0">
        <div className="anchor" id="key_features" />
        <div className="key_innner">
          <div className="container">
            <div className="dotes_blue">
              <img src={blue_dotes} alt="image" />
            </div>
            <div className="section_title">
              <span className="title_badge">Key Feature</span>
              <h2>Powerful features</h2>
            </div>
            <div className="swiper_container">
              <Swiper
                ref={swiperRef}
                id="feature_slider"
                className="swiper-wrapper"
                data-aos="fade-up"
                data-aos-duration={1500}
                data-aos-once
                slidesPerView={3}
                spaceBetween={15}
                onSwiper={(it) => (swiperRef.current = it)}
                breakpoints={Breakpoints}
              >
                {featureItems.map((item, index) => (
                  <SwiperSlide className="item" key={index}>
                    <div className="feature_box">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                      <div className="img">
                        <img src={item.image} alt="image" />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <SliderNextButton onClick={swipeNext} />
              <SliderPrevButton onClick={swipePrev} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Keyfeature;
