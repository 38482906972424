import React from 'react';
import arrowNextImage from '../../assets/images/arrow_next.svg';

const SliderNextButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="swiper_control_next swiper_control_button">
      <img className="swiper_control_img" src={arrowNextImage} alt="next" />
    </button>
  );
};

export default SliderNextButton;
