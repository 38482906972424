import React from 'react';
import arrowPrevImage from '../../assets/images/arrow_prev.svg';

const SliderPrevButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="swiper_control_prev swiper_control_button">
      <img className="swiper_control_img" src={arrowPrevImage} alt="prev" />
    </button>
  );
};

export default SliderPrevButton;
