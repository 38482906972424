import React, { useEffect } from 'react';
import Header from '../Header/Main';
import Banner from '../Menhome/Banner';
import Keyfeature from '../Menhome/Keyfeature';
import Aboutus from '../Menhome/Aboutus';
import TextList from '../Menhome/TextList';
import Service from '../Menhome/Service';
import HowWork from '../Menhome/HowWork';
import Review from '../Menhome/Review';
import Interface from '../Menhome/Interface';
import Text from '../Menhome/Text';
import Blog from '../Menhome/Blog';
import Footer from '../Footer/Main';
import Aos from 'aos';
import Faq from '../Menhome/Faq';

function Main() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <Keyfeature />

      <Aboutus />
      <TextList />
      <Service />
      <HowWork />
      <Review />
      <Faq />
      <Interface />
      <Text />
      {/* <Blog /> */}
      <Footer />
    </>
  );
}

export default Main;
